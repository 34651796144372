import React from "react"
import Img from "gatsby-image"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Carousel = ({ images }) => {
  const settings = {
    // dots: false,
    // infinite: true,
    // speed: 250,
    // slidesToShow: 1,
    // // autoPlay: true,
    // slidesToScroll: 1,
    // centerMode: true,
    // centerPadding: "200px",
    // // className: "slick-settings",
    // variableWidth: true,
    // autoplaySpeed: 500,

    dots: true,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true
  };
  

  return (
    <div>
      <Slider {...settings}>
      { images.map((node, index) => (
        <div className="slick-item"><Img key={index} fixed={ node.localFile.childImageSharp.fixed } /></div>
      ))}
      
      {/* <div className="slick-item"><img src="https://picsum.photos/800/500"/></div>
      <div className="slick-item"><img src="https://picsum.photos/500/800"/></div> */}
      </Slider>
    </div>
  )
}

export default Carousel